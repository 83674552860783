import { globalStore, dictsStore } from "@/store";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(quarterOfYear);

export const // 接口参数替换
    replaceMatch = (url, match) => {
        let resultUrl = url;
        for (const key in match) {
            if (match.hasOwnProperty(key)) {
                const item = match[key];
                resultUrl = resultUrl.replace(`:${key}`, item);
            }
        }
        return resultUrl;
    },
    // 判断是否在微信浏览器
    isWechatBrowser = () => {
        var ua = navigator.userAgent.toLowerCase();
        const isWx = /micromessenger/i.test(ua);
        const isWxWork = /wxwork/i.test(ua);
        return isWx && !isWxWork;
    },
    // 检测设备是iOS / Android
    testingDevices = () => {
        const u = navigator.userAgent;
        const isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; //android终端
        const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
        return (isAndroid && "adr") || (isiOS && "ios");
    },
    wXWorkAutoLogin = () => {
        const userAgent = navigator.userAgent;
        const inWXWork = /wxwork/i.test(userAgent) || /workwechat/i.test(userAgent);
        const hasCodeParams = getQueryString(window.location.search, "code");
        return inWXWork && !hasCodeParams;
    },
    // 判断是手机端还是pc端
    isMobileOpen = () => {
        const userAgent = navigator.userAgent;
        const isMoblie =
            userAgent.indexOf("Android") > -1 ||
            userAgent.indexOf("iPhone") > -1 ||
            userAgent.indexOf("iPad") > -1 ||
            userAgent.indexOf("iPod") > -1 ||
            userAgent.indexOf("Symbian") > -1;
        return isMoblie;
    },
    deepGet = (object, path, defaultValue) => {
        return (
            (!Array.isArray(path) ? path?.replace(/\[/g, ".")?.replace(/\]/g, "")?.split(".") : path)?.reduce((o, k) => getText(o, k, defaultValue), object) ||
            defaultValue
        );
    },
    getDict = (code, params) => {
        const enums = deepGet(dictsStore, ["dicts", code], params) || [];
        return enums;
    },
    isZh = () => {
        return globalStore.language === "zh-CN";
    },
    // 获取路由参数
    getQueryString = (locationSearch, name) => {
        let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
        let r = locationSearch?.substr(1)?.match(reg);
        if (r != null) return decodeURI(r?.[2]);
        return undefined;
    },
    getText = (o = {}, k, params) => {
        return typeof o[k] === "function" ? o[k](params) : o[k];
    },

    // 格式化时间
    formateDate = (value, type, obj) => {
        const {days, month, diffDay, tz} = obj ||{}
        if (!value) return undefined;
        // 有tz 不能new Date
        const val = tz ? dayjs.tz(value, tz) : dayjs(new Date(value));
        const types = {
            ms: val.valueOf(),
            s: val.second(),
            m: dayjs(val, "mm"),
            h: dayjs(val, "HH"),
            d: val.date(),
            month: val.month() + 1,
            year: val.year(),
            ds: val.startOf("day"),
            lmds: val.add(-1, "month").startOf("day"), //上个月当天的时间
            de: val.endOf("day").unix(), // 获取的是秒
            lmde: val.add(-1, "month").endOf("day").unix(), //上个月当天的时间，获取的是秒
            ym: val.format("YYYY/MM"),
            ymd: val.format("YYYY/MM/DD"),
            ymdhm: val.format("YYYY/MM/DD HH:mm"),
            hm: val.format("HH:mm"),
            monthsymd: val.add(month, "month").format("YYYY/MM/DD"),
            monthsymdhm: val.add(month, "month").format("YYYY/MM/DD HH:mm"),
            daysymd: val.add(days, "days").format("YYYY/MM/DD"),
            days: val.add(days, "days").format("YYYY/MM/DD HH:mm"),
            daysms: val.add(days, "days"),
            daysNum: val.diff(diffDay, "day"),
            q: val.quarter(), // 当前季度
            qsds: val.startOf("quarter").startOf("day"), // 当前季度第一天毫秒
            qeds: val.endOf("quarter").startOf("day"), // 本季度最后一个月最后一天毫秒
            qeymd: val.endOf("quarter").format("YYYY/MM/DD"), // 本季度最后一个月最后一天
            qeym: val.endOf("quarter").format("YYYY/MM"), // 本季度最后一个月
            lqeym: val.add(-1, "quarter").endOf("quarter").format("YYYY/MM"), // 上季度最后一个月
            ymdZh: val.format("YYYY年 MM月 DD日"),
        };
        return types[type] || types.ms;
    },
    // 删除缓存
    delSession = (name, newName) => {
        if (name && newName) {
            for (const key in sessionStorage) {
                if (sessionStorage.hasOwnProperty(key)) {
                    if (key.indexOf(name) !== -1 && key !== newName) {
                        sessionStorage.removeItem(key);
                    }
                }
            }
        }
    },
    getLabelName = (item, labelKey) => {
        if (labelKey) {
            return item?.[`sc_${labelKey}`] || item?.[`tc_${labelKey}`] || item?.[`en_${labelKey}`] || item?.[labelKey];
        } else {
            return item?.sc_name || item?.tc_name || item?.en_name || item?.name || undefined;
        }
    },
    getDictsStr = (code, id, {labelKey = "", idKey = "", separator = ", ", dataKey = "", datas = []} = {}) => {
        const enums = code ? deepGet(dictsStore, ["dicts", code]) || [] : datas || [];
        const ids = (id !== null && id !== undefined && (Array.isArray(id) ? id : [id])) || [];
        let strArr = [];
        for (let n = 0, len = ids.length; n < len; n++) {
            const idItem = ids[n];
            let item = enums?.find((item) => `${idKey ? item[idKey] : item.id}` === `${idItem}`);
            item = dataKey ? deepGet(item, dataKey) : item;
            const name = getLabelName(item, labelKey);
            name && strArr.push(name);
        }
        return strArr.join(separator) || undefined;
    },
    getAddressStr = (location, last) => {
        const separator = "-";
        const {regionsData = {}, address} = location || {},
            countryName = getLabelName(regionsData),
            stateName = getLabelName(regionsData?.child),
            cityName = getLabelName(regionsData?.child?.child),
            countryStr = countryName ? `${separator}${countryName}` : "",
            sname = stateName ? `${separator}${stateName}` : "",
            cname = cityName ? `${separator}${cityName}` : "",
            addressStr = address ? `${separator}${address}` : "";
        const lastStr = cityName || stateName || countryName;
        const str = `${countryStr}${sname}${cname}${addressStr}`;
        return (last && lastStr) || str?.slice(1) || undefined;
    },
    // 删除本地缓存
    delLocalStorage = (name, newName) => {
        if (name && newName) {
            for (const key in localStorage) {
                if (localStorage.hasOwnProperty(key)) {
                    if (key.indexOf(name) !== -1 && key !== newName) {
                        localStorage.removeItem(key);
                    }
                }
            }
        }
    },
    //移除html标签
    removeHtmlTags = (htmlString) => {
        return htmlString.replace(/<[^>]*>/g, '');
    },
    isPhoneNumber = (str) => {
        // 正则表达式匹配11位手机号
        var phonePattern = /^1\d{10}$/;
        return phonePattern.test(str);
    }
    ;