import { action, computed, observable } from 'mobx'
import {routers} from "@/router/menuConfig";
import request from '@/request';
import {baseRouter} from "@/router/menuConfig";
import {isZh, getQueryString} from '@/utils/tool';
class GlobalStore {
    @observable localLanguage = localStorage.getItem("language");
    @observable loading = false;
    @observable navigate = null;
    @observable modal = false;
    @observable baseModal = {};
    @observable customModal = {};
    @observable previewImgModal = {};
    @observable scrollTop;
    @observable headerTitle = null;
    @observable hidePopver = {};
    @observable public_header_color = null;
    @observable public_logo = null;
    @observable custom_websites = {
        hk09863: {
            sc_logo: 'https://static.acecamptech.com/system/leapmotor/leapmotor_logo_sc.png',
            en_logo: "https://static.acecamptech.com/system/leapmotor/leapmotor_logo_en.png",
            primary_color: '#000'
        }
    };
    @observable bottomHeight = 12;

    // @ts-ignore
    @computed get tabMenu() {
        return baseRouter()[0]?.children?.filter((item) => item?.menu && item);
    }
    // @ts-ignore
    @computed get language() {
        let resule = "zh-CN";
        const localLanguage = this.localLanguage && this.localLanguage !== "null" ? this.localLanguage : undefined;
        // @ts-ignore
        const browserLanguage = (navigator.language || navigator.userLanguage).match(/zh/) ? "zh-CN" : "en-US";
        resule = localStorage.getItem("language") || localLanguage || browserLanguage;
        return resule.indexOf("zh") !== -1 ? "zh-CN" : "en-US";
    }
    // 切换语言
    @action changeLanguage = (value) => {
        if (value && value !== "null") {
            localStorage.setItem("language", value);
            this.localLanguage = value;
            this.setLanguage();
        }
        this.changeDocTitle();
    };
    // 设置语言
    @action setLanguage = () => {
        return request({
            api: "putSetLanguage",
            method: "put",
        });
    };

    @action initCustomWebsite(public_logo, public_header_color) {
        const company = getQueryString(window.location.search,'custom_company');
        if(company && this.custom_websites[company]) {
            this.public_header_color = this.custom_websites[company].primary_color;
            this.public_logo = isZh() ? this.custom_websites[company].sc_logo : this.custom_websites[company].en_logo;
        } else if (public_logo !== undefined) {
            this.public_header_color = public_header_color || null;
            this.public_logo = public_logo;
        }
    }

    // 修改title
    @action changeDocTitle() {
        this.initCustomWebsite()
        const menuConfig = [...routers()];
        const url = window.location.pathname;
        return menuConfig.map((item) => {
            if (item.children) {
                return item.children.map((item1) => {
                    const path = item1.path.split(":")[0];
                    if (url.indexOf(path) !== -1) {
                        document.title = item1?.mainTitle || item1.title;
                        return true;
                    }
                    return false;
                });
            } else {
                if (url.indexOf(item.path) !== -1) {
                    document.title = item?.mainTitle || item.title;
                    return true;
                }
                return false;
            }
        });
    }
    // 打开弹窗禁止页面滚动
    @action disScroll(visible) {
        const body = document.querySelector("body");
        this.modal = visible;
        if (visible) {
            body.style.width = "100%";
            this.scrollTop = document.scrollingElement.scrollTop;
            body.style.position = "fixed";
            document.body.style.top = -this.scrollTop + "px";
            body.style.overflow = "hidden";
        } else {
            document.body.style.position = "static";
            document.scrollingElement.scrollTop = this.scrollTop;
            body.style.overflow = "auto";
        }
    }

    // 关闭基础弹窗
    @action hideBaseModal() {
        this.baseModal.visible = false;
        setTimeout(() => {
            this.baseModal = {};
        }, 200);
    }

    // 关闭弹窗
    @action hideModal() {
        this.baseModal.visible = false;
        this.customModal.visible = false;
        setTimeout(() => {
            this.baseModal = {};
            this.customModal = {};
        }, 200);
    }

    // 点击空白处关闭气泡
    @action handleHidePopver() {
        const values = Object.values(this.hidePopver);
        if (values?.length > 0) {
            this.hidePopver = {};
        }
    }
}

const globalStore = new GlobalStore();
export default globalStore;