const en = {
    chinese: "Chinese",
    english: "English",
    pageLoading: "Loading...",
    loaded: "All loaded",
    loadMore: "Slide up to load more",
    searchPlace: "Enter keywords to search",
    inputNumber: "Please enter number",
    release: "Pull down to refresh",
    releaseFinish: "Refreshed",
    releaseLoad: "Pull down to load more",
    releaseLoadFinish: "Loading completed",
    inputPlace: "Please enter",
    selectPlace: "Please select",
    formRequired: "is required",
    optional: "Optional",
    empty: "No data",
    reset: "Reset",
    giveUp: "Give up",
    confirm: "OK",
    cancel: "Cancel",
    close: "Close",
    gotIt: "Got it",
    exit: "Exit",
    pageStatusText: "Page does not exist",
    contactInfo: "Contact information",
    backHome: "Back to Home",
    nav: {
        signIn: "Log in",
        expertDatabase: "Expert Database",
        demand: "Demand",
        newDemand: "New Demand",
        expertDetail: "Expert Details",
    },
    expert: {
        detail: "Expert Detail"
    },
    demand: {
        detail: "Demand Detail",
        pastetext: "Paste Text",
    },
    status: {
        registering: "Active",
        registered: "Registered",
        playback: "Replay",
        finished: "Finished",
    },
    search: {
        searchKeyword: "Please enter keywords/company name/stock code/industry...",
        pastRecords: "Search history",
        hostSearch: " Hot search",
        hotTopics: "Hot discussion",
        relatedTopics: "Related discussion",
        relatedOwner: "Related camp Owner",
        name: "Search",
        corporation: "company",
    },
    btn: {
        expand: "Expand",
        close: "Close",
        pasteAndRecognize: "Paste and recognize",
    },
};

export default en;
