import { lazy } from 'react';
import homeIcon from "@/assets/images/menuIcon/home.svg";
import homeActiveIcon from "@/assets/images/menuIcon/home-active.svg";
import { Add, Dongdong, UserAdd } from '@nutui/icons-react';
import {t} from "@/i18n";

const baseRouter = () => [
        //专家详情
        {
            title: t("nav.expertDetail"),
            path: "/detail/:id",
            component: lazy(() => import("@/pages/expertDetail")),
        },
        //登陆页面
        {
            title: t("nav.signIn"),
            path: "/login",
            component: lazy(() => import("@/pages/login")),
        },
        //新增需求页面
        {
            title: t("nav.newDemand"),
            path: "/addDemand",
            component: lazy(() => import("@/pages/addDemand")),
        },
    ],
    tabRouter = () => [
        // //新增需求
        // {
        //     title: t("nav.newDemand"),
        //     path: "/newDemand",
        //     icon: <span className='iconfont icon-xinjianwendang' />,
        //     component: lazy(() => import("@/pages/addDemand")),
        // },
        //需求
        {
            title: t("nav.demand"),
            path: "/demand",
            icon: <span className='iconfont icon-xuqiuguanli' />,
            component: lazy(() => import("@/pages/demand")),
        },
        //专家库
        {
            title: t("nav.expertDatabase"),
            path: "/search",
            icon: <span className='iconfont icon-zhuanjiaku_tuandui' />,
            // iconActive: homeActiveIcon,
            component: lazy(() => import("@/pages/search")),
            
        },
    ],
    routers = () => [
        {
            path: "/",
            component: lazy(() => import("@/layouts/baseLayout")),
            redirect: "/search",
            exact: true,
            children: [...tabRouter(), ...baseRouter()]
        }
    ];

export {routers, baseRouter, tabRouter};