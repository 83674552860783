let baseURL = "",
    baseHostUrl = "",
    agentId = "",
    HOST = process.env.REACT_APP_NODE_ENV,
    localHost = "",
    base = {
        staging: "https://api.ca3staging.com",
        testing: "https://api.ca3test.com",
        canary: "https://api.ca3canary.com",
        prod: "https://api.acecamptech.com",
    },
    baseHost = {
        staging: "https://admin.ca3staging.com",
        testing: "https://admin-m.ca3test.com",
        canary: "https://admin.ca3canary.com",
        prod: "https://admin-m.acecamptech.com",
    },
    agentIds = {
        staging: "1000003",
        canary: "1000011",
        testing: "1000008",
        prod: "1000037",
    };
    localHost = "testing";
    baseURL = base[HOST] || base[localHost];
    baseHostUrl = baseHost[HOST] || baseHost[localHost];

    agentId = agentIds[HOST] || agentIds[localHost];
export {HOST, baseURL, baseHostUrl, agentId};
