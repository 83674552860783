
// store
import {inject, observer} from "mobx-react";
import { useEffect, useState } from "react";
import { ConfigProvider, BackTop } from "@nutui/nutui-react";
import zh from "@nutui/nutui-react/dist/locales/zh-CN";
import RouterView from "@/router";
import {getQueryString} from "@/utils/tool";
import { useLocation, useNavigate } from 'react-router-dom';
//components
import LoadingCom from "@/components/Loading";

const Layout = (props) => {
    const [isReady, setIsReady] = useState(false);
    const globalStore = props.store.globalStore;
    const userStore = props.store.userStore;
    const dictsStore = props.store.dictsStore;
    const location = useLocation();
    globalStore.navigate = useNavigate();

    useEffect(() =>{
        getData()
    }, [])

    const getData = async () => {
        userStore.redirect = decodeURIComponent(getQueryString(location.search, "redirect"));
        await userStore.getUserInfo();
        await dictsStore.getDicts();
        setIsReady(true)
    }


    return(
        (isReady && (
            <div className="layout" id="layout" onClick={() => globalStore.handleHidePopver()}>
               <ConfigProvider locale={zh}>
                    <RouterView />
               </ConfigProvider>
               <BackTop target="backTop" />
            </div>
        )) || <LoadingCom loading={true} />
    )
}

export default inject("store")(observer(Layout));